import React from 'react';
import { siteObj } from '../generalData';
import Product from '../ProductInfo';
import {
   ProductsContainer,
   ProductsH2,
   ProductsH3,
   ProductsWrapper
} from './ProductsElements';

const Products = () => {
   const { slogan1, slogan2, info } = siteObj.productSection;

   return (
      <ProductsContainer>
         <ProductsH2>{ slogan1 }<br />{ slogan2 }</ProductsH2>
         <ProductsH3>{ info }</ProductsH3>
         <ProductsWrapper>
            <Product />
         </ProductsWrapper>
      </ProductsContainer>
   );
};

export default Products;
