import React, { useState } from 'react';
import Footer from '../components/Footer';
import InfoSection from '../components/InfoSection';
import Navbar from '../components/Navbar';
import ProductContent from '../components/ProductContent';
import Products from '../components/ProductSection';
import Sidebar from '../components/SideBar';

const ProductPage = ({match}) => {
   
   const [withBg] = useState(true);

   const [isOpen, setIsOpen] = useState(false);

   const toggle = () => {
      setIsOpen(!isOpen)
   }

   return (
      <>
         <Sidebar isOpen={isOpen} toggle={toggle} />
         <Navbar withBg={ withBg } toggle={toggle} /> 
         <ProductContent product_id={match} />
         <Products />
         <InfoSection />
         <Footer />
      </>
   );
};

export default ProductPage;