import React from 'react';
import { siteObj } from '../generalData';
import {
   Column1,
   Heading, InfoContainer,
   Information, InfoRow,
   InfoSpam, InfoWrapper,
   Subtitle, TextWrapper
} from './InfoElements';

const Info = () => {

   const siteData = siteObj.infoSection;
   const {telefono, email, titulo, subtitulo} = siteData;

   return (
      <>
         <InfoContainer id='contact'>
            <InfoWrapper>
               <InfoRow>
                  <Column1>
                     <TextWrapper>
                        <Heading>{titulo}</Heading>
                        <Subtitle>{subtitulo}</Subtitle>
                        <Information>Llame a <InfoSpam>{telefono}</InfoSpam></Information>
                        <Information>Escriba a <InfoSpam>{email}@</InfoSpam><InfoSpam>incineradoresmercosur</InfoSpam>.com</Information>
                     </TextWrapper>
                  </Column1>
               </InfoRow>
            </InfoWrapper>
         </InfoContainer>
      </>
   );
};

export default Info;
