import React from 'react';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import Navbar from '../components/Navbar';
import Products from '../components/ProductSection';
import Seo from '../components/Seo';

const Home = () => {

   return (
      <>
         <Seo  />
         <Navbar />
         <HeroSection />
         <Products />
         <InfoSection />
         <Footer />
      </>
   )
}

export default Home
