import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ProductsCard = styled(Link)`
   background: #fff;
   border-radius: 10px;
   max-height: 380px;
   transition: all 0.2s ease-in-out;
   text-align: center;
   text-decoration: none;
   color: #000;

   &:hover {
      transform: scale(1.01);
      transition: all 0.2s ease-in-out;
      box-shadow: 0 3px 6px rgba(0,0,0,0.2);
      cursor: pointer;
   }

   @media screen and (max-width: 768px) {
      max-height: 440px;
   }

   @media screen and (max-width: 320px) {
      max-height: 480px;
   }
`;

export const ProductImg = styled.div`
   width: 100%;
   height: 160px;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;

   @media screen and (max-width: 768px) {
      height: 260px;
   }

   @media screen and (max-width: 320px) {
      max-height: 160px;
   }

`;

export const ProductsIcon = styled.img`
   height: auto;
   width: 100%;
`;

export const ProductsH3 = styled.h2`
   padding: 15px; 
   font-size: 1rem;
   font-weight: 900;
   color: #707070;
   white-space: pre-wrap;
`;

export const ProductsP = styled.p`
   padding: 15px;
   font-size: 0.9rem;
   font-weight: 300;
   text-align: left;

   @media screen and (max-width: 820px) {
      font-size: 0.8rem;  
   }

   @media screen and (max-width: 768px) {
      font-size: 0.9rem;   
   }
`;

export const ProductBtnWrap = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
`;

export const ProductBtn = styled(Link)`
   text-decoration: none;
   margin-top: 15px;
   padding: 5px 20px;
   color: #09734B;
   background: transparent;
   border-radius: 6px;
   transition: all 0.2s ease-in-out;
   box-shadow: 0 1px 3px rgba(0,0,0,0);
   font-size: 0.8rem;

   &:hover {
      background: #09734B;
      color: #fff;
      transition: all 0.3s ease-in-out;
      box-shadow: 0 1px 3px rgba(0,0,0,0.2);
      cursor: pointer;
   }
   &:active {
      background: #09734B;
      color: #fff;
      transition: all 0.3s ease-in-out;
   }

   @media screen and (max-width: 768px) {
      background: #09734B;
      color: #fff;
      padding: 10px;
      font-size: 0.9rem;
      box-shadow: none;
      text-align: center;
      &:hover {
         background: #94C124;
         color: #000;
         transition: all 0.3s ease-in-out;
         box-shadow: 0 1px 3px rgba(0,0,0,0.2);
      }
   }
`;

export const ProductWrap = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   cursor: pointer;

   &:hover {
      & ${ProductsCard} {
         transform: scale(1.01);
         transition: all 0.2s ease-in-out;
         box-shadow: 0 3px 6px rgba(0,0,0,0.2);
      }
      & ${ProductBtn} {
         background: #09734B;
         color: #fff;
         transition: all 0.3s ease-in-out;
         box-shadow: 0 1px 3px rgba(0,0,0,0.2);

         &:hover {
            background: #09734B;
            color: #fff;
            transition: all 0.3s ease-in-out;
         }
      }
   }
`;