import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ProductContainer = styled.div`
   background: #fff;
   // margin-top: -80px;
   display: flex;
`;

export const ProductWrapper = styled.div`
   display: grid;
   z-index: 1;
   width: 100%;
   max-width: 1200px;
   margin-right: auto;
   margin-left: auto;
   padding: 0 24px;
   justify-content: center;
   align-items: center;
`;

export const ProductWrap = styled.div`
   display: grid;
   margin: 30px 0;
   margin-bottom: 50px;
   justify-content: flex-start;
   align-items: center;
`;

export const ProductRow = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr;
   align-items: flex-start;
   grid-template-areas: 'col1 col2';

   @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-areas: 'col2 col2' 'col1 col1';
   }

`;

export const ProductTitle = styled.h1`
   text-align: center;
   font-weight: 400;
   padding-bottom: 35px;
`;

export const ProductContent = styled.div`
   font-size: 14px;
   padding: 0 15px;
   grid-area: col1;
`;

export const ProductP = styled.p`
   padding-bottom: 15px;
   text-align: justify;
   width: 100%;
   text-justify: inter-word;
`;

export const ProductImage = styled.div`
   padding: 0 15px;
   grid-area: col2;

   @media screen and (max-width: 768px) {
      margin-bottom: 35px;
   }
`;

export const ProductImg = styled.img`
   width: 100%;
   border-radius:4px;
   box-shadow: 0 3px 6px rgba(0,0,0,0.2);
`;

export const Modelos = styled.div`
   padding-bottom: 15px;
   li {
      list-style: none;
      padding: 4px 2px;
   }
   a {
      text-decoration: none;
      color: green;
      font-weight: 600;
   }
`;

export const ProductSubNav = styled.div`
   padding: 0 20px 10px;
   font-size: 12px;
`;

export const ProductSubNavLink = styled(Link)`
   text-decoration: none;
`;

export const ProductVideo = styled.iframe`
   margin: 40px auto 20px;
   border: none;
   width: 100%;
   max-width: 640px;
   max-height: 340px;
   display: flex;
   background: rgb(9,115,75);

   @media screen and (max-width: 520px) {
      max-height: 200px;
   }

`;