import React, { useState } from 'react';
import PlayImg from '../../assets/images/play.svg';
import { siteObj } from '../generalData';
import { Modal } from '../Modal';
import {
   HeroBackground, HeroBorder, HeroContainer,
   HeroH1, HeroSlogan,
   HeroVideo,
   HeroVideoPlay, HeroVideoPlayLink, HeroVirtudes,
   HeroVirtudesWrap, HeroWrap,
   VirtudBox, Virtudes
} from './HeroElements';

const HeroSection = () => {

   const [showModal, setShowModal] = useState(false);

   const openModal = () => {
      setShowModal(prev => !prev);
   };

   const {slogan} = siteObj.heroSection;

   return (

      <HeroContainer>
         <HeroBorder></HeroBorder>
         <HeroBackground>
            <HeroWrap>
               <HeroSlogan>
                  <HeroH1>{slogan}</HeroH1>
               </HeroSlogan>
            </HeroWrap>
         </HeroBackground>
         <HeroVirtudes>
            <HeroVirtudesWrap>
               <HeroVideo>
                  <HeroVideoPlayLink to='/' onClick={openModal}><HeroVideoPlay src={PlayImg} alt='Reproducir Video' width="77" height="77" /></HeroVideoPlayLink>
               </HeroVideo>
               <Modal showModal={showModal} setShowModal={setShowModal} />
               <Virtudes>
                  <VirtudBox>Líderes mundiales en tecnología de incineración.</VirtudBox>
                  <VirtudBox>Calidad de construcción y longevidad inigualable.</VirtudBox>
                  <VirtudBox>Distribuido a más<br />de 100 países.</VirtudBox>
                  <VirtudBox>Responsable<br />ambientalmente.</VirtudBox>
                  <VirtudBox>Socio de confianza con<br />35 años de experiencia.</VirtudBox>
                  <VirtudBox>Diseñado y construido<br />en Inglaterra.</VirtudBox>
               </Virtudes>
            </HeroVirtudesWrap>
         </HeroVirtudes>
      </HeroContainer>
   );
};

export default HeroSection;
