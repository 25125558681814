import { Link as LinkR } from 'react-router-dom';
import styled from 'styled-components';
import BgImg from '../../assets/images/bg.webp';
import BgVideo from '../../assets/images/video.webp';

export const HeroContainer = styled.div`
   background: #fff;
   margin-top: -80px;
   display: flex;
   justify-content: center;
   align-items: start;
   align-content: space-between; 
   padding: 0;
   height: 800px;
   position: relative;
   z-index: 1

   &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), 
                  linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
      z-index: 2;
   }
      
`;

export const HeroBorder = styled.div`
   background: #E8E8E8;   
   height: 80px;
   width: 100%;
   position: absolute;
   z-index: 2;
`;

export const HeroBackground = styled.div`
   width: 100%;
   height: 800px;
   position: relative;
   z-index: 3;
   background-repeat: no-repeat;
   background-position: top right;
   background-size: 80%;
   background-image: url(${BgImg});

   @media screen and (max-width: 820px) {
      background-size: 100%;
   }
   
   @media screen and (max-width: 420px) {
      background-size: 70%;
   }
`;

export const HeroWrap = styled.div`
   padding: 48px 24px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: start;
   max-width: 1200px;
   margin: 0 auto;
   margin-top: 80px;
`;

export const HeroSlogan = styled.div`
   display: flex;
   justify-content: center;
   max-width: 550px;
   padding-top: 32px; 

   @media screen and (max-width: 820px) {
      padding-top: 32px; 
      max-width: 350px;
   }

   @media screen and (max-width: 420px) {
      padding-top: 0; 
      max-width: 250px;
   }
`;

export const HeroH1 = styled.h1`
   font-size: 1.9rem;  

   @media screen and (max-width: 820px) {
      font-size: 1.2rem; 
   }

   @media screen and (max-width: 420px) {
      font-size: 0.8rem; 
   }

`;

export const HeroVirtudes = styled.div`
   height: 120px;
   width: 100%;
   position: absolute;
   top: 680px;
   z-index: 4;
   background: #09734B;
   display: flex;

   @media screen and (max-width: 820px) {
      height: 130px;
   }

   @media screen and (max-width: 620px) {
      min-height: 180px;
   }

   @media screen and (max-width: 420px) {
      top: 580px;
      min-height: 220px;
   }
`;

export const HeroVirtudesWrap = styled.div`
   padding: 24px;
   padding-top: 12px;
   max-width: 1200px;
   margin: 0 auto;
   display: grid;
   grid-template-columns: 1fr 1fr;
    
   @media screen and (max-width: 820px) {
      grid-template-columns: 1fr;
   }


`;

export const HeroVideo = styled.div`
   background: #000;
   color: #fff;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border-radius: 10px;
   max-height: 320px;
   padding: 30px;
   box-shadow: 0 5px 8px rgba(0,0,0,0.4);
   transition: all 0.2s ease-in-out;
   margin-top: -240px;
   width: 520px;
   height: 400px;
   background-image: url(${BgVideo});
   background-size: cover;
   background-position: center center;
    
   &:hover {
      transform: scale(1.001);
      box-shadow: 0 5px 8px rgba(0,0,0,0.6);
      transition: all 0.2s ease-in-out;
   }

   
   @media screen and (max-width: 820px) {
      width: 470px;
      height: 300px;
      margin-top: -320px;
   }

   @media screen and (max-width: 620px) {
      width: 100%;
   }

   @media screen and (max-width: 420px) {
      width: 100%;
      height: 280px;
      margin-top: -300px;
   }

`;

export const HeroVideoPlayLink = styled(LinkR)`
   text-decoration: none;
   cursor: pointer;
`;

export const HeroVideoPlay = styled.img`

   transition: all 0.2s ease-in-out;   
      
   &:hover {
      transform: scale(1.1);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
   }
`;

export const Virtudes = styled.div`
   display: flex;
   flex-flow: row wrap;
   justify-content: center;
   flex: 1 0px;
   padding-left: 20px;

   @media screen and (max-width: 420px) {
      padding-left: 0;
   }
`;

export const VirtudBox = styled.div`
   padding: 5px;
   width: 33%;
   font-size: 12px;
   color: #fff;

   @media screen and (max-width: 820px) {
      padding: 10px 15px;
   }

   @media screen and (max-width: 620px) {
      padding: 8px 6px
   }

   @media screen and (max-width: 420px) {
      width: 49%;
      padding: 10px 5px;
   }

`;