import React from 'react';
import { FaEnvelope, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import { siteObj } from '../generalData';
import {
   CloseIcon, Icon, SidebarContainer,
   SidebarLink,
   SidebarMenu,
   SidebarRoute,
   SidebarWrapper
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {

   const { whatsapp, telefono } = siteObj.general;

   return (
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
         <Icon onClick={toggle}>
            <CloseIcon />
         </Icon>
         <SidebarWrapper>
            <SidebarMenu>
               <SidebarLink to='contact' onClick={toggle}><FaEnvelope />&nbsp;Contactar</SidebarLink>
               <SidebarRoute title="Enviar mensaje a whatsapp" href={ `https://wa.me/${whatsapp}` }><FaWhatsapp />&nbsp; Whatsapp</SidebarRoute>
               <SidebarRoute title="Llamar por teléfono" href={ `tel:+${telefono}` }><FaPhoneAlt />&nbsp;Llamar</SidebarRoute>
            </SidebarMenu>
         </SidebarWrapper>
      </SidebarContainer>
   );
};

export default Sidebar;
