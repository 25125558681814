import React from 'react';
import { productObj } from '../ProductInfo/Data';
import Seo from '../Seo';
import {
   Modelos, ProductContainer,
   ProductContent,
   ProductImage,
   ProductImg, ProductP, ProductRow,
   ProductSubNav, ProductSubNavLink, ProductTitle,
   ProductVideo, ProductWrap, ProductWrapper
} from './ProductElements';

const ProductInfo = ({product_id}) => {

   return (
         <>
            {productObj.map((producto, i) => 
               (
                  (producto.id === product_id.params.id) && (
                     <div key={i}>
                        <Seo data={producto} />
                        <ProductContainer>
                           <ProductWrapper>
                              <ProductWrap>
                                 <ProductSubNav>
                                    <ProductSubNavLink to='/'>Inicio</ProductSubNavLink> / Soluciones
                                 </ProductSubNav>
                                 <ProductTitle>{producto.title}</ProductTitle>
                                 <ProductRow>
                                    <ProductContent>
                                       {producto.content.map((parrafo, j) => (
                                          <ProductP>{parrafo}</ProductP>
                                       ))}
                                       <br />
                                       
                                       {producto.products.map((serie, key) => {
                                          const listItems = serie.modelos.map((modelo, key) => 
                                             <li key={key}><a href={modelo.link} rel='noreferrer noopener' target='_blank' title={`Ver modelo ${modelo.title} en el sitio de Addfield`}>{modelo.title}</a></li>
                                          );
                                          
                                          return (
                                             <Modelos key={key}>
                                                <h3>{serie.title}</h3>
                                                <ul>{listItems}</ul>
                                             </Modelos>
                                          );
                                       })}
                                       <br />
                                       {producto.video && <ProductVideo src={`${producto.video}?autoplay=0&modestbranding=1&color=white&iv_load_policy=3&showinfo=0&rel=0`} width='560' height='315' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></ProductVideo>}
                                    </ProductContent>
                                    <ProductImage><ProductImg src={producto.img} /></ProductImage>
                                 </ProductRow>
                              </ProductWrap>
                           </ProductWrapper>
                        </ProductContainer>
                     </div>
                  )
               ) 
            )}
         </>
   );
};

export default ProductInfo;
