import styled from 'styled-components';

export const ProductsContainer = styled.div`
   height: 800px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: #E8E8E8;

   @media screen and (max-width: 1000px) {
      height: 1200px;
   }

   @media screen and (max-width: 768px) {
      height: 2300px;
   }

   @media screen and (max-width: 620px) {
      padding-top: 0px;
      height: 2400px;
   }

   @media screen and (max-width: 320px) {
      height: 2500px;
   }
`;

export const ProductsH2 = styled.h2`
   text-align: center;
   font-weight: 400;

   @media screen and (max-width: 768px) {
      padding: 20px 10px;
   }
`;

export const ProductsH3 = styled.h3`
   text-align: center;
   font-weight: 400;
   margin-bottom: 40px;
   padding: 20px 20%;
   color: #666;

   @media screen and (max-width: 768px) {
      padding: 20px 10px;
   }
`;

export const ProductsWrapper = styled.div`
   max-width: 1200px;
   margin: 0 auto;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr;
   align-items: center; 
   grid-gap: 16px;
   padding: 0 35px;
   transition: all 0.2s ease-in-out;


   @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
      transition: all 0.2s ease-in-out;
   }

   @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      padding: 0 20px;
      transition: all 0.2s ease-in-out;
   }
`;