export const productObj = [
   {
      id: 'agropecuarios',
      title: 'Incineradores\nAgropecuarios',
      img: require('../../assets/images/animales.webp'),
      description: 'Todas las gamas de incineración para la industria agropecuaria. Animales de granja, semillas vencidas, residuos agricolas, envases agroquímicos, etc.',
      linkText: 'para animales',
      content: [
         'Ofrecemos soluciones altamente eficientes para la eliminación segura de todos los tipos de residuos agrícolas en Granjas productoras. Proporcionando la solución perfecta para la industria agrícola en todo el mundo.',
         'El propósito fue creado para destruir de manera confiable todas las bajas existentes y desechos cárnicos en las granjas productoras de animales.',
         'Instalado en más de 95 países para muchas de las principales organizaciones agrícolas del mundo.',
         'Para asegurarnos de que tenga la mejor solución para mantener la seguridad de su granja, trabajaremos estrechamente con usted para así entregarle el incinerador más adecuado cumpliendo con los requisitos y necesidad de su negocio.',
         'Todos los incineradores Addfield están aprobados por DEFRA, cumplen con el Reglamento de la UE sobre subproductos animales, (CE) No 142/2011 y cumplen con las normas internacionales. Ofrecemos una garantía de uso de horas de funcionamiento ilimitada por 12 meses. Los incineradores son acreditados ISO 9001 y 14001 asegurando la calidad en todo nuestro servicio y negocio.',
         'Otros tipos de residuos también son adecuados, consulte a uno de nuestros expertos.'
      ],
      products: [
         { 
            title: 'Gama de productos',
            modelos: [
               { title: 'Mini AB (250Kg)', link: 'https://addfield.com/es/machines/mini-ab-incinerador-de-pollos/' },
               { title: 'Mini (350Kg)', link: 'https://addfield.com/es/machines/mini-incinerador-de-aves-de-corral/' },
               { title: 'Mini Plus (500Kg)', link: 'https://addfield.com/es/machines/mini-plus-incinerador-de-ovejas/' },
               { title: 'SB (750Kg)', link: 'https://addfield.com/es/machines/sb-incineradores-de-cerdos-y-cerdas/' },
               { title: 'TB (1300Kg)', link: 'https://addfield.com/es/machines/incinerador-de-vaca-tb/' },
               { title: 'TB-AB (2000Kg)', link: 'https://addfield.com/es/machines/tb-ab-incinerador-de-vaca-y-ganado/' }
            ]
         }
      ],
      video: 'https://player.vimeo.com/video/332446473?autoplay=0&amp;loop=1&amp;muted=1&amp;autopause=0'
   },
   {
      id: 'peces',
      title: "Incineradores para\nPeces",
      img: require('../../assets/images/peces.webp'),
      description: 'Gama líder en la industria de incineradores de eliminación de residuos acuícolas. Diseñado específicamente para residuos de alta humedad.',
      linkText: 'para peces',
      content: [
         'La gama de incineradores de residuos para la Aquacultura de Addfield ofrece soluciones altamente eficientes para la eliminación segura de todas las formas de residuos con alta humedad. Entregando la solución perfecta para piscifactorías en todo el mundo.',
         'Los incineradores de acuicultura de Addfield son únicos en su diseño y construcción. Construido el propósito de destruir de manera confiable todos los residuos sólidos y con alta humedad',
         'La gama de incineradores de desechos marinos de Addfield está aprobada por DEFRA y cumple con la normativa (CE) n.º 1069/2009 para los subproductos de animales en la UE.',
         'Addfield Environmental ofrece 12 meses de garantía ilimitada. Los incineradores son acreditados ISO 9001 y 14001 asegurando la calidad en todo nuestro servicio y negocio.'
      ],
      products: [
         {
            title: 'Serie Aqua',
            modelos: [
               { title: 'Mini AB AQUA (250Kg)', link: 'https://addfield.com/es/machines/mini-ab-aqua-2/'},
               { title: 'Mini AQUA (350Kg)', link: 'https://addfield.com/es/machines/mini-aqua-2/'},
               { title: 'TB-AB AQUA (2000Kg)', link: 'https://addfield.com/es/machines/tb-ab-aqua-2/'}
            ]
         },
         {
            title: 'Serie Thunder',
            modelos: [
               { title: 'Thunder 500', link: 'https://addfield.com/es/machines/thunder-500-4/'},
               { title: 'Thunder 1000', link: 'https://addfield.com/es/machines/thunder-1000-4/'}
            ]
         }
      ],
      video: 'https://player.vimeo.com/video/431387517?autoplay=0&loop=1&muted=1&autopause=0'
   },
   {
      id: 'residuos-medicos',
      title: "Incineradores para\nresiduos médicos",
      img: require('../../assets/images/medicos.webp'),
      description: 'Soluciones altamente eficientes para la eliminación segura de todas las formas de desechos peligrosos, farmacéuticos y médicos.',
      linkText: 'para residuos',
      content: [
         'La gama de incineradores para desechos médicos de Addfield ofrece soluciones altamente eficientes para la eliminación segura de todas las formas de desechos peligrosos, farmacéuticos y médicos. Proporcionando la solución perfecta y una ayuda para hospitales, laboratorios y agencias en todo el mundo.',
         'Las máquinas Addfield están diseñadas para ser un 40% más eficientes en el consumo de combustible y fabricadas con altos estándares extremadamente, utilizando solo materiales de la más alta calidad para darle una máquina que podría durar décadas.',
         'Los incineradores Médicos de Addfield Medical son únicos en su diseño y construcción. Construidos con el propósito para destruir de manera confiable eficiente y económica todos sus desechos peligrosos.',
         'Todos nuestros incineradores están cubiertos por una garantía de 12 meses de uso de horas de funcionamiento ilimitadas. ISO 9001 y 14001 acreditados asegurando la calidad en todo nuestro servicio y negocio.'
      ],
      products: [
         { 
            title: 'Gama incineradora MP',
            modelos: [
               { title: 'MP-100 (100Kg/por dia)', link: 'https://addfield.com/es/machines/mp100-3/' },
               { title: 'MP-200 (200Kg/por dia)', link: 'https://addfield.com/es/machines/mp200-3/' },
               { title: 'MP-300 (300Kg/por dia)', link: 'https://addfield.com/es/machines/mp-300-es-un-incinerado-medico/' },
               { title: 'MP-400 (400Kg/por dia)', link: 'https://addfield.com/es/machines/la-maquina-de-incineracion-mp400/' },
               { title: 'MP-500 (500Kg/por dia)', link: 'https://addfield.com/es/machines/mp500-3/' }
            ]
         }, 
         {
            title: 'Rango de alta capacidad C',
            modelos: [
               { title: 'C100 (100Kg/hr)', link: 'https://addfield.com/es/machines/c100-incinerador-de-residuos-medicos/' },
               { title: 'C200 (200Kg/hr)', link: 'https://addfield.com/es/machines/incinerador-de-desechos-de-hogar-escalonado-c200/' }
            ]
         }, 
         {
            title: 'Uso de emergencia de GM',
            modelos: [
               { title: 'GM350', link: 'https://addfield.com/es/machines/top-loading-general-medical-incinerator-gm350/' },
               { title: 'GM750', link: 'https://addfield.com/es/machines/gm750-3/' },
               { title: 'GM1300', link: 'https://addfield.com/es/machines/incineradora-gm-1300-para-residuos-medicos/' }
            ]
         }
      ],
      video: 'https://www.youtube.com/embed/YHxWhGBOSX4'
   },
   {
      id: 'a-medida',
      title: 'Incineradores hechos\na la medida',
      img: require('../../assets/images/a-medida.webp'),
      description: 'Hechos conforme a sus especificaciones y requisitos. Un equipo especializado diseñará y fabricará la máquina de incineración a su medida.',
      linkText: 'a medida',
      content: [
         'Producimos varios sistemas de incineración de alta capacidad con un rango de tasas de combustión de 150-1200 kg/h. Tenemos una experiencia considerable en incineración de alta capacidad que abarca muchas industrias variadas en todo el mundo. Los sistemas de incineración de alta capacidad de Addfield ofrecen opciones automáticas de carga, limpieza, filtración y monitoreo.',
         'Los sistemas que suministramos incluyen sistemas rotativos, de solera estática, de ram y tornillo, todos con capacidad para tratar y destruir los desechos especificados al 100%.'
      ],
      products: [
         {
            title: 'Incineración animal',
            modelos: [
               { title: 'A200 (200Kg/hr)', link: 'https://addfield.com/es/machines/a200/' },
               { title: 'A500 (500Kg/hr)', link: 'https://addfield.com/es/machines/a500/' },
               { title: 'TB-AB-MAX', link: 'https://addfield.com/es/machines/tb-ab-max-4/' },
               { title: 'RAPID1000', link: 'https://addfield.com/es/machines/rapid-1000/' },
            ]
         },
         {
            title: 'Incineración rotativa',
            modelos: [
               { title: 'R1200 (1200Kg/hr)', link: 'https://addfield.com/es/machines/r1200-3/' }
            ]
         }, 
         {
            title: 'Pérdida General',
            modelos: [
               { title: 'G100 (100kg/hr)', link: 'https://addfield.com/es/machines/g100-general-municipal-incineradora/' },
               { title: 'G250 (250Kg/hr)', link: 'https://addfield.com/es/machines/g250-3/' },
               { title: 'G350 (350Kg/hr)', link: 'https://addfield.com/es/machines/g350-3/' },
               { title: 'G500 (500Kg/hr)', link: 'https://addfield.com/es/machines/g500-3/' }
            ]
         }, 
         {
            title: 'Residuos Clínicos',
            modelos: [
               { title: 'C100 (100Kg/hr)', link: 'https://addfield.com/es/machines/c100-incinerador-de-residuos-medicos/' },
               { title: 'C200 (200Kg/hr)', link: 'https://addfield.com/es/machines/incinerador-de-desechos-de-hogar-escalonado-c200/' },
               { title: 'C300 (300Kg/hr)', link: 'https://addfield.com/es/machines/2467/' },
               { title: 'C500 (500Kg/hr)', link: 'https://addfield.com/es/machines/c500-3/' },
               { title: 'C1000 (1000Kg/hr)', link: 'https://addfield.com/es/machines/alta-capacidad-biologico-residuo-incinerador-c1000/' }
            ]
         }
      ],
      video: 'https://www.youtube.com/embed/Joh4fklDL3A'
   }
];