import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

const Seo = props => {
  const { data } = props;

  const postTitle = data ? (data || {}).title : "";
  const postDescription = data ? (data || {}).description : "";
  const postCover = data ? (data || {}).img : "";

  const title = postTitle !== "" ? `${postTitle} - Incineradores Mercosur` : "Incineradores Mercosur - Distribuidor para Argentina, Brasil, Paraguay y Uruguay";
  const description = postDescription !== "" ? postDescription : "Incineradores Mercosur distribuidor autorizado de Addfield para Argentina, Brasil, Paraguay y Uruguay. Incineradores agropecuarios, incineradores para peces, incineradores para residuos médicos e incineradores hechos a medida.";
  const image = postCover !== "" ? `https://incineradoresmercosur.com/${postCover}` : `https://incineradoresmercosur.com/static/media/animales.92d02a83.webp`;
  const url = window.location.hostname;

  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={description} />
      <meta itemprop="image" content={image} />
      {/* OpenGraph tags */}
      <meta name="og:url" content={url} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={image} />
      <meta name="og:type" content="website" />
      <meta name="fb:app_id" content="" />
      {/* Twitter Card tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="" />
    </Helmet>
  );
};

Seo.propTypes = {
  data: PropTypes.object
};

export default Seo;