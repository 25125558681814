import React from 'react';

const Error404 = () => {
   return (
      <>
         <h1>No encontramos la página que intenta ingresar</h1>    
      </>
   );
};

export default Error404;
