import styled from 'styled-components';
import InfoBg from '../../assets/images/infobg.webp';

export const InfoContainer = styled.div`
   color: #fff;
   background-image: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ), url(${InfoBg});
   background-position: center center;
   background-size: cover;
`;

export const InfoWrapper = styled.div`
   display: grid;
   z-index: 1;
   height: 266px;
   width: 100%;
   max-width: 1200px;
   margin-right: auto;
   margin-left: auto;
   padding: 0 24px;
   justify-content: flex-start;
   align-items: center;
   
   @media screen and (max-width: 768px) {
      height: 366px;
      padding-bottom: 80px;
      display: flex;
      justify-content: center;
   }

   @media screen and (max-width: 420px) {
      height: 466px;
   }
`;

export const InfoRow = styled.div`
   display: grid;
   grid-auto-columns: minmax(auto, 1fr);
   align-items: flex-start;
   grid-template-areas: 'col1' 'col2';
`;

export const Column1 = styled.div`
   padding: 0 15px;
   grid-area: col1;
   
   @media screen and (max-width: 1000px) {
      width: 500px;
   }

   @media screen and (max-width: 768px) {
      width: 100%;
   }
`;

export const TextWrapper = styled.div`
   max-width: 540px;
   padding-top: 0;

   @media screen and (max-width: 768px) {
      text-align: center;
      max-width: 100%;
   }
`;

export const Heading = styled.h1`
   font-size: 18px;
   line-height: 1.1;
   font-weight: 600;
   color: ${({ lightText }) => (lightText ? '#010606' : '#f7f8fa')};
`;

export const Subtitle = styled.p`
   max-width: 540px;
   margin-bottom: 25px;
   font-size: 18px;
   line-height: 24px;
   color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`;

export const Information = styled.p`
   max-width: 440px;
   margin-bottom: 0;
   font-size: 18px;
   line-height: 24px;
   color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};

   @media screen and (max-width: 420px){
      text-align: center;
      padding-bottom: 15px;
   }
`;

export const InfoSpam = styled.span`
   @media screen and (max-width: 420px){
      display: block;
   }
`;