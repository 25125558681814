import styled from 'styled-components';

export const FormWrapper = styled.div`
   position: relative;
   margin-top: -300px;
   transition: all 0.2s ease-in-out;
   display: flex;
   justify-content: flex-end;
   padding-right: 10px;

   @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: -140px;
      padding-right: 0;
      transition: all 0.2s ease-in-out;
   }
`;

export const FormContainer = styled.form`
   background: #fff;
   border-radius: 10px;
   transition: all 0.2s ease-in-out;
   text-align: center;
   box-shadow: 0 3px 6px rgba(0,0,0,0.2);
   padding: 15px;
   display: grid;
   width: 90%;

   @media screen and (max-width: 768px) {
      width: 100%;
   }

   .message {
      transition: all 0.3s ease-in-out;
      box-shadow: 0 3px 6px rgba(0,0,0,0.2);
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      font-size: 12px;
      color: white;

      &.proccess {
         background-color: #09734B;
         transition: all 0.3s ease-in-out;
      }
      &.success {
         background-color: #94C124;
         transition: all 0.3s ease-in-out;
         cursor: pointer;
      }
      &.error {
         background-color: red; 
         transition: all 0.3s ease-in-out;
         cursor: pointer;
      }
   }
   
`;

export const FormTitle = styled.h2`
   font-size: 18px;
   line-height: 1.1;
   font-weight: 400;
   margin: 10px 0;
   padding-bottom: 10px;
   text-align: left;
`;

export const FormLabel = styled.label`
   display: none;
`;

export const FormImput = styled.input`
   padding: 12px 12px;
   margin-bottom: 26px;
   border: none; 
   border-radius: 2px;
   box-shadow: 0 1px 3px rgba(0,0,0,0.2);
   transition: all 0.2s ease-in-out;
   color: #666;

   &:focus {
      transition: all 0.2s ease-in-out;
      box-shadow: 0 2px 5px rgba(0,0,0,0.2);
      color: #000;
   }
`;

export const FormTextarea = styled.textarea`
   padding: 12px 12px;
   margin-bottom: 26px;
   border: none; 
   border-radius: 2px;
   font-size: 13.3333px;
   box-shadow: 0 1px 3px rgba(0,0,0,0.16);
   transition: all 0.2s ease-in-out;
   color: #666;

   &:focus {
      transition: all 0.2s ease-in-out;
      box-shadow: 0 2px 5px rgba(0,0,0,0.2);
      color: #000;
   }
`;

export const FormButtonWrap = styled.div`
   display: flex;
   justify-content: flex-end;
`;

export const FormButton = styled.button`
   background: #09734B;
   color: #fff;
   padding: 10px 16px;
   border: none;
   border-radius: 4px;
   font-size: 20px;
   transition: all 0.2s ease-in-out;

   &:hover, &:focus {
      background: #94C124;
      color: #010101;
      box-shadow: 0 1px 3px rgba(0,0,0,0.2);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
   }
`;

export const Error = styled.span`
   color: #999;
   text-align: left;
   font-size: 12px;
   padding: 3px 2px;
   font-style: oblique;
   margin-top: -24px;
`;
