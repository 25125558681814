import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import styled from 'styled-components';

export const Nav = styled.nav`
   background: ${({withBg}) => (withBg) ? '#E8E8E8' : 'transparent'};
   height: 80px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 1rem;
   position: relative;
   top: 0;
   z-index: 10;

   @media screen and (max-width: 960px) {
      transition: 0.8s all ease;
   }
`;

export const NavbarContainer = styled.div`
   display: flex;
   justify-content: space-between;
   height: 80px;
   z-index: 1;
   width: 100%;
   padding: 0 24px;
   max-width: 1200px;
`;

export const NavLogoSlogan = styled.div`
   display: flex;
   justify-self: flex-start;

   
   @media screen and (max-width: 768px) {
      display: grid;
      justify-self: flex-start;
   }
`;

export const NavLogo = styled(LinkR)`
   color: rgb(112,112,112);
   justify-self: flex-start;
   cursor: pointer;
   font-size: 1.5rem;
   display: flex;
   align-items: center;
   font-weight: bold;
   text-decoration: none;
`;

export const Logo = styled.img`
   height: 65px;
   width: 240px;
   
   @media screen and (max-width: 768px) {
      height: 45px;
      width: 180px;
   }
`;

export const Slogan = styled.div`
   font-size: 12px;
   display: grid;
   justify-self: flex-start;
   margin-left: 10px;
   padding-left: 10px;
   
   &::before {
      content: '';
      border-left: 1px solid #09734B;
      position: absolute;
      height: 55%;
      top: 25%;
      margin-left: -12px;
  }

   @media screen and (max-width: 768px) {
      margin-left: 1px;
      padding-left: 1px;
      margin-top: -6px;
      font-size: 10px;
      &::before {
         display: none;
     }
   }


`;

export const SloganP = styled.p`
   margin: auto 0;
   font-weight: 700;
   color: #09734B; 
   
   &.first {
      margin-bottom: 0;
   }
   &.second {
      margin-top: 0;
   }
`;

export const NavMenu = styled.ul`
   align-items: center;
   list-style: none;
   text-align: center;
   margin-right: 10px;
   display: ${({withBg}) => (withBg) ? 'flex' : 'none'};

   @media screen and (max-width: 420px) {
      display: none;
   }
`;

export const NavItem = styled.li`
   height: 80px;
`;

export const NavLinksInt = styled(LinkS)`
   color: #000;
   display: flex;
   align-items: center;
   text-decoration: none;
   padding: 0 0.8rem;
   height: 100%;
   cursor: pointer;
   transition: 0.2s all ease;
   font-size: 1.4rem;

   &>svg {
      fill: #09734B;
      transition: 0.2s all ease;
   }
   &:hover > svg {
      fill: #94C124;
      transform: scale(1.2);
      transition: 0.2s all ease;
   }

`;

export const NavLinksExt = styled.a`
   color: #000;
   display: flex;
   align-items: center;
   text-decoration: none;
   padding: 0 0.8rem;
   height: 100%;
   cursor: pointer;
   transition: 0.8s all ease;
   font-size: 1.4rem;

   &>svg {
      fill: #09734B;
      transition: 0.2s all ease;
   }
   &:hover > svg {
      fill: #94C124;
      transform: scale(1.2);
      transition: 0.2s all ease;
   }
`;

export const MobileIcon = styled.div`
   display: none;

   @media screen and (max-width: 420px) {
      display: ${({withBg}) => (withBg) ? 'block' : 'none'};
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: #fff;
   }
   &>svg {
      fill: #09734B;
      transition: 0.2s all ease;
   }
   &:hover > svg {
      fill: #94C124;
      transform: scale(1.2);
      transition: 0.2s all ease;
   }
`;
