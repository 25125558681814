import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import {
   Error, FormButton,
   FormButtonWrap,
   FormContainer,
   FormImput,
   FormLabel,
   FormTextarea,
   FormTitle,
   FormWrapper
} from './ContactElements';

const ContactForm = () => {

   const [enviando, setState] = useState({mostrar: false, mensaje: null, estado: null });

   const { register, errors, handleSubmit } = useForm({
      mode: "onSubmit",
      reValidateMode: "onChange"
    });

    const reset = () => {
      setState({mostrar: false, mensaje: null, estado: null });
    }
   
   const onSubmit = async (data, e) => {

      setState({mostrar: true, estado: 'proccess', mensaje: "Enviando mensaje..."});

      const options = {
         method: 'POST', 
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({data}),
      };
      
      const response = await fetch('/mail-api/', options);
      if (response.ok) {
         const json = await response.json();
         (json.status === 'success') && e.target.reset();
         setState({ mostrar: true, estado: json.status, mensaje: json.message });
      } else {
         setState({ mostrar: true, estado: 'error', mensaje: 'Ocurrió un error inesperado, por favor vuelva a intentar más tarde.' });
      }

   }
   
   return (
      <>
         <FormWrapper>
            <FormContainer onSubmit={handleSubmit(onSubmit)} noValidate>
               <FormTitle>Envíe un mensaje</FormTitle>
               { enviando.estado && <div className={ `message ` + enviando.estado } onClick={ reset }>{enviando.mensaje}</div>}
               <FormLabel htmlFor='nombre'>Nombre</FormLabel>
               <FormImput 
                  name='nombre' type='text' placeholder='Su nombre'
                  ref={register({ 
                     required: "Su nombre es requerido", 
                     pattern: {
                        value: /^[A-Za-z ñÑ]+$/,
                        message: "Su nombre solo puede contener letras"
                     }, 
                     minLength: {
                        value: 3,
                        message: "Su nombre debe ser de por lo menos 3 caracteres"
                     } 
                  })}  
               />
               {errors.nombre && <Error>{errors.nombre.message}</Error>}
               <FormLabel htmlFor='email'>Email</FormLabel>
               <FormImput 
                  name='email' type="email" placeholder='Su correo electrónico' 
                  ref={register({ 
                     required: "Su correo electrónico es requerido", 
                     pattern: {
                        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: "El formato del correo electrónico no es válido"
                     }
                  })}  
               />
               {errors.email && <Error>{errors.email.mensaje}</Error>}
               <FormLabel htmlFor='pais'>País</FormLabel>
               <FormImput 
                  name='pais' type='text' placeholder='Su país' 
                  ref={register({ 
                     required: "Su país es requerido", 
                     pattern: {
                        value: /^[A-Za-z ñÑ]+$/,
                        message: "Su país solo puede contener letras"
                     }
                  })}
               />
               {errors.pais && <Error>{errors.pais.message}</Error>}
               <FormLabel htmlFor='mensaje'>Mensaje</FormLabel>
               <FormTextarea 
                  name='mensaje' placeholder='Su mensaje' 
                  ref={register({ 
                     required: "Su mensaje es requerido", 
                     minLength: {
                        value: 10,
                        message: "Su mensaje debe ser de por lo menos 20 caracteres"
                     } 
                  })} 
               />
               {errors.mensaje && <Error>{errors.mensaje.message}</Error>}
               <FormButtonWrap>
                  <FormButton type='submit'>Enviar</FormButton>
               </FormButtonWrap>
            </FormContainer>
         </FormWrapper>
      </>
   );
};

export default ContactForm;
