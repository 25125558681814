import React, { useEffect, useState } from 'react';
import { FaBars, FaEnvelope, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { IconContext } from 'react-icons/lib';
import LogoImg from '../../assets/images/logo.svg';
import { siteObj } from '../generalData';
import { Logo, MobileIcon, Nav, NavbarContainer, NavItem, NavLinksExt, NavLinksInt, NavLogo, NavLogoSlogan, NavMenu, Slogan, SloganP } from './NavbarElements';

const Navbar = ({ withBg, toggle }) => {

   const [scrollNav, setScrollNav] = useState(false);
   const { whatsapp, telefono, slogan1, slogan2 } = siteObj.general;

   const changeNav = () => {

      if (window.scrollY >= 80) {
         setScrollNav(true);
      } else {
         setScrollNav(false);
      }
   }

   useEffect(() => {
      window.addEventListener('scroll', changeNav)
   }, [])

   return (
      <>
         <IconContext.Provider value={{ color: '#fff' }}>
            <Nav withBg={withBg} scrollNav={scrollNav}>
               <NavbarContainer>
                  <NavLogoSlogan>
                     <NavLogo to='/'><Logo src={LogoImg} alt='Incineradores Mercosur' width="240" height="65" /></NavLogo>
                     <Slogan>
                        <SloganP className='first'>{ slogan1 }</SloganP>
                        <SloganP className='second'>{ slogan2 }</SloganP>
                     </Slogan>
                  </NavLogoSlogan>
                  <MobileIcon withBg={withBg} onClick={toggle}>
                     <FaBars />
                  </MobileIcon>
                  <NavMenu withBg={withBg}>
                     <NavItem>
                        <NavLinksInt to='contact'
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        ><FaEnvelope /></NavLinksInt>
                     </NavItem>
                     <NavItem>
                        <NavLinksExt title="Enviar mensaje a whatsapp" href={ `https://wa.me/${whatsapp}` }><FaWhatsapp /></NavLinksExt>
                     </NavItem>
                     <NavItem>
                        <NavLinksExt title="Llamar por teléfono" href={ `tel:+${telefono}` }><FaPhoneAlt /></NavLinksExt>
                     </NavItem>
                  </NavMenu>
               </NavbarContainer>
            </Nav>
         </IconContext.Provider>
      </>
   )
}

export default Navbar
