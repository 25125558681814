import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FooterContainer = styled.div`
   background-color: #fff;
`;

export const FooterWrap = styled.div`
   padding: 48px 10px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: strech;
   max-width: 1200px;
   margin: 0 auto;
`;

export const FooterRow = styled.div`
   display: grid;
   grid-auto-columns: minmax(auto, 1fr);
   align-items: center;
   grid-template-areas: 'col1 col2';

   @media screen and (max-width: 768px) {
      grid-template-areas: 'col2' 'col1' ;
   }
`

export const Column1 = styled.div`
   margin-bottom: 15px;
   padding: 0 15px;
   grid-area: col1;

`;

export const Column2 = styled.div`
   margin-bottom: 15px;
   padding: 0 15px;
   grid-area: col2;
   justify-content: center;
   align-items: flex-end;

`

export const FooterLogo = styled(Link)`
   color: #fff;
   justify-self: start;
   cursor: pointer;
   font-size: 1.5rem;
   display: flex;
   align-items: center;
   margin-bottom: 16px;

   @media screen and (max-width: 768px) {
      margin-top: 40px;
      justify-content: center;
   }
`;

export const Logo = styled.img`
   height: 65px;
   width: 240px;
   margin-bottom: 10px;
`;

export const FooterAddfieldConteiner = styled.div`
   display: grid;
   grid-auto-columns: minmax(auto, 1fr);
   align-items: top;
   grid-template-areas: 'col1 col2';

   @media screen and (max-width: 420px) {
      grid-template-areas: 'col1' 'col2' ;
   }
`;

export const FooterAddfieldColumn1 = styled.div`
   margin-bottom: 15px;
   
   @media screen and (max-width: 768px) {
      text-align: center;
   }
`;

export const FooterAddfieldColumn2 = styled.div`
   margin-bottom: 15px;

   @media screen and (max-width: 768px) {
      text-align: center;
   }
`;

export const FooterAddfieldP = styled.p`
   font-size: 14px;
   font-weight: 400;
   padding-bottom: 8px;
`;

export const FooterAddfieldLink = styled.a`
   text-decoration:none;
`;

export const FooterAddfieldLogo = styled.img`
   width: 160px;
   margin-bottom: 10px;
`;

export const FooterAccreditationLogo1 = styled.img`
   width: 130px;
   padding-right: 4px;
   margin-bottom: 10px;
`;

export const FooterAccreditationLogo2 = styled.img`
   width: 60px;
   padding-right: 4px;
   margin-bottom: 10px;
`;