import React from 'react';
import { productObj } from '../ProductInfo/Data';
import {
   ProductBtn, ProductBtnWrap, ProductImg, ProductsCard,
   ProductsH3,
   ProductsIcon,
   ProductsP, ProductWrap
} from './ProductsElements';

const ProductInfo = () => {

   return (

      productObj.map(({id, title, description, img, linkText}) => 
         <ProductWrap key={id}>
            <ProductsCard to={`/solucion/${id}`}>
               <ProductsH3>{title}</ProductsH3>
               <ProductImg>
                  <ProductsIcon src={img} alt={title} widt="270" height="270"/>
               </ProductImg>
               <ProductsP>{description}</ProductsP>
            </ProductsCard>
            <ProductBtnWrap>
            <ProductBtn to={`/solucion/${id}`} aria-labelledby="">Vea soluciones {linkText}</ProductBtn>
            </ProductBtnWrap>
         </ProductWrap>
      )
   )
}

export default ProductInfo;
