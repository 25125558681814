import React from 'react';
import styled from 'styled-components';
import {MdClose} from 'react-icons/md';

const Background = styled.div`
   width: 100%;
   height: 100%;
   background: rgba(0,0,0,0.8);
   position: fixed;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 0;
   right: 0;
`;

const ModalWrapper = styled.div`
   width: 720px;
   height: 405px;
   transition: all 0.2s ease-in-out;
   background: #fff;
   color: #000;
   display: grid;
   position: absolute;
   z-index: 20;
   border-radius: 10px;


   @media screen and (max-width: 768px) {
      width: 90%;
   }
`;

const ModalContent = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   transition: all 0.2s ease-in-out;
`;

const ModalVideo = styled.iframe`
   margin: auto;
   border: none;
   width: 100%;
   max-width: 720px;
   max-height: 405px;
   display: flex;
   background: rgb(9,115,75);
   transition: all 0.2s ease-in-out;
   border-radius: 10px;
`;

const CloseModalButton = styled(MdClose)`
   cursor: pointer; 
   position: absolute;
   top: -10px;
   right: -10px;
   width: 32px;
   height: 32px;
   padding: 0;
   z-index: 20;
   border-radius: 50px;
   background: #ffF;
`;


export const Modal = ({showModal, setShowModal}) => {
   return (
      <>
         {showModal ? (
            <Background>

                  <ModalWrapper showModal={showModal}>
                     <ModalContent>
                        <ModalVideo width="720" height="405" src="https://www.youtube.com/embed/JXUm7jC7Q50?autoplay=1&modestbranding=1&iv_load_policy=3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></ModalVideo>
                        <CloseModalButton aria-label='Cerrar video' onClick={() => setShowModal(prev => !prev)} />
                     </ModalContent>
                  </ModalWrapper>
            </Background>
         ): null }
      </>
   );
};

