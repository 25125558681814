export const siteObj = {
   general: {
      whatsapp: '595972118212',
      telefono: '595972118212',
      slogan1: 'International Leaders of',
      slogan2: 'Incineration Solutions'
  }, 
  heroSection: {
     slogan: 'Suministramos los incineradores más robustos y eficientes fabricados en Inglaterra, reconocidos mundialmente por su alta eficiencia y longevidad.'
  }, 
  infoSection: {
      titulo: 'Tiene preguntas?',
      subtitulo: 'Pregunte a los expertos en el campo de la incineración',
      telefono: '+(595) 972 11 82 12',
      email: 'info'
  }, 
  productSection: {
     slogan1: 'Los Mejores Incineradores del Mercado.',
     slogan2: 'Una Solución para cada Necesidad.',
     info: 'AddField es el único fabricante en el Reino Unido que fabrica el 100% de sus máquinas en sus instalaciones y no depende de contratos de segundos o terceros para esta fabricación.'
  }
};