import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages';
import NoMatch from './pages/404';
import ProductPage from './pages/products';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" component={ Home } exact />
        <Route path="/solucion/:id" component={ ProductPage } />
        <Route component={NoMatch} />
      </Switch>
    </Router>
  );
}

export default App;
